<template>
  <div class="modal-card" style="height: auto; width: 100%">
    <header class="modal-card-head">
      <p class="modal-card-title title is-6">{{ name }}</p>
    </header>
    <section class="modal-card-body">
      <div class="title is-7">{{ name }} isimli {{ type }} silinecektir, onaylıyor musunuz?</div>
    </section>
    <footer class="modal-card-foot" style="justify-content: flex-end">
      <b-button size="is-small" icon-left="delete" class="is-danger is-half" @click="$emit('close')">İptal Et</b-button>
      <b-button size="is-small" icon-left="check" class="is-success is-half" :disabled="loading" :loading="loading"
                @click="remove">Evet, Sil
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'RemoveModal',
  props: ['id', 'service', 'name', 'type', 'rmId'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    remove () {
      this.loading = true
      this.service
          .remove(this.id, this.rmId)
          .then(() => {
            this.$buefy.toast.open({
              message: 'Silindi',
              type: 'is-success'
            })
            this.$emit('close')
          })
          .catch(err => {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger'
            })
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style>
.vm--modal {
  height: auto !important;
}
</style>
