import { http } from "@/utils/axios";
const SERVICE = 'profile/';

const METHODS = {
    PASSWORD_CHANGE: 'password-change',
    RESET_2FA: 'reset-2fa',
    UPDATE: 'update',
    ME: 'me',
    BANNED_LIST: 'banned-list',
    REMOVE_BAN: 'remove-ban',
    ADD_BAN: 'add-ban'
};

export default {
    me(params = {}) {
        return http.get(SERVICE + METHODS.ME, { params });
    },
    reset2fa(params = {}) {
        console.log(params)
        return http.put(SERVICE + METHODS.RESET_2FA, params);
    },
    update(params = {}) {
        return http.put(SERVICE + METHODS.UPDATE, params);
    },
    passwordChange(params = {}) {
        return http.put(SERVICE + METHODS.PASSWORD_CHANGE, params);
    },
    bannedList() {
        return http.get(SERVICE+METHODS.BANNED_LIST, {});
    },
    removeBan(params = {}) {
        return http.post(SERVICE+METHODS.REMOVE_BAN, params);
    },
    addBan(ip) {
        return http.post(SERVICE+METHODS.ADD_BAN, ip);
    }
};
