import { http } from '@/utils/axios'

const SERVICE = 'report/'

const METHODS = {
	dashboard: 'dashboard',
	dashboardGroup: 'dashboard-group',
	cage: 'cage',
	cagev2: 'cage/v2',
	cageDetail: 'cage/detail',
	yearlyDeposit: 'yearly-deposit',
	dailyDeposit: 'daily-deposit',
	yearlyWithdraw: 'yearly-withdraw',
	player: 'player',
	playerDetail: 'player-detail',
	dailyAccount: 'daily-account',
	failedTransactions: 'failed-transactions',
	failedTransactionsSub: 'failed-transactions-sub',
	failedTransactionsDetail: 'failed-transactions-detail',
	group: 'group',
	status: 'status',
	statusChangeReport: 'status-change',
	bankTransferGroup: "bank-transfer-group",
	bankTransferGroupSub: "bank-transfer-group-sub"
}

export default {
	dashboard (params = {}) {
		return http.get(SERVICE + METHODS.dashboard, { params })
	},
	dashboardGroup (params = {}) {
		return http.get(SERVICE + METHODS.dashboardGroup, { params })
	},
	cage (params = {}) {
		return http.get(SERVICE + METHODS.cage, { params })
	},
	cagev2 (params = {}) {
		return http.get(SERVICE + METHODS.cagev2, { params })
	},
	cageDetail (params = {}) {
		return http.get(SERVICE + METHODS.cageDetail, { params })
	},
	yearlyDeposit (params = {}) {
		return http.get(SERVICE + METHODS.yearlyDeposit, { params })
	},
	dailyDeposit (params = {}) {
		return http.get(SERVICE + METHODS.dailyDeposit, { params })
	},
	yearlyWithdraw (params = {}) {
		return http.get(SERVICE + METHODS.yearlyWithdraw, { params })
	},
	player (params = {}) {
		return http.get(SERVICE + METHODS.player, { params })
	},
	playerDetail (params = {}) {
		return http.get(SERVICE + METHODS.playerDetail, { params })
	},
	dailyAccount (params = {}) {
		return http.get(SERVICE + METHODS.dailyAccount, { params })
	},
	failedTransactionReport (params = {}) {
		return http.get(SERVICE + METHODS.failedTransactions, { params })
	},
	failedTransactionsSubReport(params = {}) {
		return http.get(SERVICE + METHODS.failedTransactionsSub, { params })
	},
	failedTransactionsDetailReport(params = {}) {
		return http.get(SERVICE + METHODS.failedTransactionsDetail, { params })
	},
	group(params = {}) {
		return http.get(SERVICE + METHODS.group, { params })
	},
	status(params = {}) {
		return http.get(SERVICE + METHODS.status, { params })
	},
	statusChangeReport(params = {}) {
		return http.get(SERVICE + METHODS.statusChangeReport, { params })
	},
	bankTransferGroup(params = {}) {
		return http.get(SERVICE + METHODS.bankTransferGroup, { params })
	},
	bankTransferGroupSub(params = {}) {
		return http.get(SERVICE + METHODS.bankTransferGroupSub, { params })
	}
}
