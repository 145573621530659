<template>
  <div class="card" >
    <header v-if="title" class="card-header">
      <p class="card-header-title" :class="titleClass">
        <b-icon v-if="icon" :icon="icon" custom-size="default"/>
        {{ title }}
      </p>
      <b-button v-if="headerIcon" class="card-header-icon" :icon-left="headerIcon"
                @click.prevent="$router.push({name: routeName})"/>
      <b-button v-if="table" class="card-header-icon" icon-left="sync" @click="refresh"/>
    </header>
    <div class="card-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    },
    routeName: {
      type: String,
      default: null
    },
    table: {
      type: Object,
      default: null
    },
    titleClass: {
      type: String,
      default: null
    }
  },
  methods: {
    refresh () {
      console.log(this.$children)
    }
  }
}
</script>
