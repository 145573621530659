import { http } from "@/utils/axios";
const SERVICE = 'banklist/';

const METHODS = {
    all: 'all'
};

export default {
    list(params = {}) {
        return http.get(SERVICE, { params });
    },
    all(params = {}) {
        return http.get(SERVICE + METHODS.all, { params });
    },
    remove(id) {
        return http.delete(SERVICE + id);
    },
    create(params = {}) {
        return http.post(SERVICE, params);
    },
    update(params = {}) {
        return http.put(SERVICE + params.uuid, params);
    },
    edit(id) {
        return http.get(SERVICE + id);
    }
};
