<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page"/>
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import RemoveModal from '@/components/RemoveModal'
import { CurrencyService as Service } from '@/services'

export default {
  name: 'Tables',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data () {
    return {
      table: {
        filter:[
            {
              name: 'Kısa Adı',
              field: 'shortName',
              type: 'input'
            }
        ],
        page: {
          title: 'Para Birimleri Listesi',
          icon: 'bank',
          actions: [
            {
              title: 'Para Birimi Oluştur',
              type: 'success',
              icon: 'plus',
              perm: 'Currency.Create',
              action: () => { this.$router.push({ name: 'currencyCreateOrUpdate' }) }
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => { this.$refs.table.loadAsyncData() }
            }
          ]
        },
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'name',
              label: 'Banka Adı',
              sortable: true
            },
            {
              field: 'shortName',
              label: 'Kısa Adı',
              sortable: true
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDate)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              sortable: false,
              width: '100px',
              actions: [
                {
                  title: 'Düzenle',
                  type: 'success',
                  column: 'row',
                  perm: 'Currency.Edit',
                  action: data => this.$router.push({ name: 'currencyCreateOrUpdate', params: { id: data.uuid } })
                },
                {
                  column: 'row',
                  title: 'Sil',
                  type: 'danger',
                  perm: 'Currency.Delete',
                  action: data => {
                    this.openModal({
                      component: RemoveModal,
                      props: {
                        id: data.uuid,
                        name: data.name,
                        service: data.service,
                        type: 'Para Birimi'
                      },
                      close: this.$refs.table.loadAsyncData
                    })
                  }
                }
              ]
            }
          ]
        }
      }
    }
  }
}
</script>
