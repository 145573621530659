<template>
	<div>
		<section class="section is-main-section">
			<table class="table" style="width: 100%">
				<thead>
					<tr>
						<th>Kullanıcı</th>
						<th>Rol</th>
						<th>Site</th>
						<th>Grup</th>
						<th>İşlemler</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(user, key) in users" :key="key">
						<td>{{ user.username }}</td>
						<td>{{ user.role }}</td>
						<td>{{ user.site }}</td>
						<td>{{ user.group }}</td>
						<td>
							<b-field grouped>
								<b-button
									size="is-small"
									type="button"
									@click="logout(user.uuid)"
									>Logout</b-button
								>
								<b-button
									size="is-small"
									type="button"
									@click="refresh(user.uuid)"
									>Refresh</b-button
								>
								<b-input
									v-model="user.message"
									size="is-small" />
								<b-button
									size="is-small"
									type="button"
									@click="
										notification(user.uuid, user.message)
									"
									>Notification</b-button
								>
							</b-field>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
	</div>
</template>

<script>
	export default {
		name: "OnlineUserList",
		data() {
			return {
				users: [],
			}
		},
		methods: {
			refresh(i) {
				this.$socket.emit("users action", { i, t: "refresh" })
			},
			logout(i) {
				this.$socket.emit("users action", { i, t: "logout" })
				setTimeout(() => {
					this.$socket.emit("users action", { i, t: "refresh" })
				}, 3000)
			},
			notification(i, m) {
				this.$socket.emit("users action", { i, t: "notification", m })
			},
		},
		mounted() {
			this.$socket.emit("users active", "sa")
			this.sockets.subscribe("usersActive", (val) => {
				this.users = val
			})
			this.sockets.subscribe("usersLogin", (val) => {
				this.users.push(val)
			})
			this.sockets.subscribe("usersLogout", (val) => {
				this.users = [...this.users.filter((item) => item.uuid !== val)]
			})
		},
		beforeDestroy() {
			this.sockets.unsubscribe("usersActive")
			this.sockets.unsubscribe("usersLogin")
			this.sockets.unsubscribe("usersLogout")
		},
	}
</script>
