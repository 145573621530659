import {http} from "@/utils/axios";
const SERVICE = 'setting/';
export default {
    update(params = {}) {
        return http.put(SERVICE + params.id, params);
    },
    edit(id) {
        return http.get(SERVICE + id);
    }
};
