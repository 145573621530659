import {http} from "@/utils/axios";

const SERVICE = 'customer/';

export default {
    list(params = {}) {
        return http.get(SERVICE, {params});
    },
    remove(id) {
        return http.delete(SERVICE + id);
    },
    create(params = {}) {
        return http.post(SERVICE, params);
    },
    update(params = {}) {
        return http.put(SERVICE + params.uuid, params);
    },
    edit(id) {
        return http.get(SERVICE + id);
    },
    transactionReport(id) {
        return http.get(SERVICE + 'transaction-report/' + id);
    }
};
