import { http } from "@/utils/axios";
const SERVICE = 'maldo/';

export default {
    withdrawList(params = {}) {
        return http.get(SERVICE+ 'withdrawals', { params });
    },
    withdrawUpdate(params = {}) {
        return http.put(SERVICE + 'withdrawals/update', params);
    },
    setting(params = {}) {
        return http.get(SERVICE+ 'setting', { params });
    },
    settingUpdate(params = {}) {
        return http.put(SERVICE + 'setting/update', params);
    },
    settingReport(params = {}) {
        return http.get(SERVICE + 'setting/report', {params});
    },
    withdrawExportExcel(params = {}) {
        return http.get(SERVICE + 'withdraw-export-excel', {params});
    },
    
};
