import {http} from '@/utils/axios'

const SERVICE = 'integration/'

export default {
    withdraw(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'withdraw', params, {headers: {sign, appKey}})
    },
    deposit(params = {}) {
        return http.post(SERVICE + 'deposit', params)
    },
    paparaDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'papara/deposit', params, {headers: {sign, appKey}})
    },
    creditCardDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'card/deposit', params, {headers: {sign, appKey}})
    },
    coinDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'coin/deposit', params, {headers: {sign, appKey}})
    },
    bankTransferDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'bank-transfer/deposit', params, {headers: {sign, appKey}})
    },
    bankTransferNotify(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'bank-transfer/notify', params, {headers: {sign, appKey}})
    },
    autoBankTransferDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'auto-bank-transfer/deposit', params, {headers: {sign, appKey}})
    },
    autoBankList(params = {}, sign, appKey) {
        return http.get('v1/' + SERVICE + 'auto-bank-list', {headers: {sign, appKey}})
    },
    payfixDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'payfix/deposit', params, {headers: {sign, appKey}})
    },
    tetherDeposit(params = {}, sign, appKey) {
        return http.post('v1/' + SERVICE + 'tether/deposit', params, {headers: {sign, appKey}})
    },
    setting(params = {}, sign, appKey) {
        return http.get('v1/' + SERVICE + 'setting', {params, headers: {sign, appKey}})
    },
    bankList(params = {}, sign, appKey) {
        return http.get('bank-list', {headers: {sign, appKey}})
    }
}
