import {http} from "@/utils/axios";

const SERVICE = 'lucky/';

export default {
    paparaDeposit(params = {}) {
        return http.post(SERVICE + 'papara/deposit', params);
    },
    withdraw(params = {}) {
        return http.post(SERVICE + 'withdraw/create', params);
    },
    getCustomer(params = {}) {
        return http.post(SERVICE + 'get-customer', params);
    },
};
